import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext, useState } from "react";
import "./MostFavorite.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const MostFavorite = () => {
  const [newProducts, setNewProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const products = newProducts?.slice(0, 4);

  // useEffect(() => {
  //   setLoading(true);
  //   fetch(`https://clariss-backend.vercel.app/newArrivals`)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setNewProducts(data);
  //       setLoading(false);
  //     });
  // }, []);

  return (
    <div className="overflow-hidden">
      <div
        className={`flex flex-col px-[10px] items-center sm:px-[20px] lg:px-[0px] lg:w-[90%] mx-auto ${
          window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
        }`}
      >
        {/* <h1 className="text-center text-2xl font-bold text-accent mt-16">
            NEW ARRIVALS
          </h1>
          <div className="w-[130px] mx-auto h-1 bg-accent my-6 mb-10"></div>
          <div className="grid grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 featured-collections ">
            {products?.map((product) => (
              <Product key={product?._id} product={product}></Product>
            ))}
          </div>
          <Link
            to="/newarrivals"
            className="text-white bg-accent px-10 rounded sm:mt-20 py-2 hover:bg-secondary ease-in-out duration-200 font-bold sm:my-10 "
          >
            View More
          </Link> */}

        <iframe
          width="1300"
          height="740"
          className="responsive-iframe"
          src="https://www.youtube.com/embed/a5iZg8PrkBg?si=HAd0aCPnBUwXjf2s?autoplay=1&playlist=a5iZg8PrkBg&loop=1"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default MostFavorite;
