import React, { useState } from "react";
import "./MobileNavMenu.css";
import logo from "../../Images/Clariss-Logo.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cart from "../Cart/Cart";
import { Link } from "react-router-dom";
import PopCart from "../PopCart/PopCart";
import NavLogin from "../NavLogin/NavLogin";
import MobileSearch from "../MobileSearch/MobileSearch";
import MobileCategory from "../MobileCategory/MobileCategory";

const MobileNavMenu = ({ popCart, handlePopCart }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [menu, setMenu] = useState(false);

  const handleSearch = (search) => {
    setShowSearch(search);
  };

  const handleMenu = (menu) => {
    setMenu(menu);
    setShowSearch(false);
    if (menu) {
      handlePopCart(false);
    }
  };

  if (showSearch || menu) {
    if (window.innerWidth < 640) {
      document.body.style.overflow = "hidden";
    }
  } else {
    document.body.style.overflow = "visible";
  }

  return (
    <div
      className={`mobile-menu-container ${
        showSearch ? "searched-nav-menu" : ""
      }`}
    >
      <div className="flex">
        <FontAwesomeIcon
          className="text-secondary mr-8 text-xl"
          icon={faBars}
          onClick={() => handleMenu(true)}
        ></FontAwesomeIcon>
        <MobileCategory menu={menu} handleMenu={handleMenu} />
      </div>

      <Link to="/">
        <img className="w-[60%] mx-auto px-4" src={logo} alt="" />
      </Link>

      <FontAwesomeIcon
        onClick={() => handleSearch(!showSearch)}
        className="text-secondary text-xl ml-auto mr-2"
        icon={faMagnifyingGlass}
      ></FontAwesomeIcon>
      <MobileSearch
        showSearch={showSearch}
        handleSearch={handleSearch}
      ></MobileSearch>
      <div className="ml-auto"></div>
      <span
        className={`overlay ${
          showSearch ? "searched-overlay" : "not-searched-overlay"
        } ${menu ? "overlay-active " : ""}`}
      ></span>
    </div>
  );
};

export default MobileNavMenu;
