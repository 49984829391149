import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import CategoryPage from "../Pages/CategoryPage/CategoryPage";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import SearchedProductPage from "../Pages/SearchedProductPage";
import NewArrivals from "../Pages/NewArrivals/NewArrivals";
import NotFound from "../Pages/NotFound";
import About from "../Pages/About";
import Shop from "../Pages/Shop";
import Sitemap from "../Pages/Sitemap";
import MainHomePage from "../Pages/MainHomePage/MainHomePage";
import ShippingPolicy from './../Pages/ShippingPolicy';
import PrivacyPolicy from './../Pages/PrivacyPolicy';
;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <MainHomePage></MainHomePage>,
      },
      {
        path: "/personal-care-home",
        element: <Home></Home>,
      },
      {
        path: "/shippingPolicy",
        element: <ShippingPolicy></ShippingPolicy>,
      },
      {
        path: "/privacyPolicy",
        element: <PrivacyPolicy></PrivacyPolicy>,
      },
      {
        path: "/newarrivals",
        element: <NewArrivals></NewArrivals>,
      },
      {
        path: "/shop",
        element: <Shop></Shop>,
      },
      {
        path: "/about",
        element: <About></About>,
      },
      {
        path: "/sitemap",
        element: <Sitemap></Sitemap>,
      },
      {
        path: "/product-category/:category",
        element: <CategoryPage></CategoryPage>,
      },
      {
        path: "/search/:searchText",
        element: <SearchedProductPage></SearchedProductPage>,
      },
      {
        path: "/*",
        element: <NotFound></NotFound>,
      },
    
      {
        path: "/product/:slug",
        element: <ProductDetails />,
      },
    ],
  }

]);

export default router;
