import React, { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Pages/Shared/Footer/Footer";
import Header from "../Components/Header/Header";
import './Main.css'

const Main = () => {
  const location = useLocation()


  return (
    <div>
     {location.pathname==="/"?"":<Header />} 
      <Outlet />
      {location.pathname==="/"?"": <Footer />} 
     
    </div>
  );
};

export default Main;
