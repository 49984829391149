import React, { useContext, useRef, useState } from "react";
import "./Product.css";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { toast } from "react-hot-toast";
import { addToDb } from "../../utilities/CartDb";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import StarRating from "../Ratings";

const Product = ({ product, list }) => {
  const navigate = useNavigate();
  const [mouseHover, setMouseHover] = useState(false);

  return (
    <div className={`product relative ${list ? "py-6" : ""}`}>
      <div
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        className="img-container cursor-pointer"
      >
        <div
          onClick={() => navigate(`/product/${product?.slug}`)}
          className="img-div rounded"
        >
          <img
            width={250}
            height={250}
            src={
              mouseHover
                ? product?.images[1]?.src
                  ? product?.images[1]?.src
                  : product?.images[0]?.src
                : product?.images[0]?.src
            }
            alt=""
            className="rounded"
          />
        </div>
      </div>

      <div className="product-details">
        <p
          onClick={() => navigate(`/product/${product.slug}`)}
          className="limit-lines text-center cursor-pointer font-bold mb-4"
          title={product?.name}
        >
          {product?.name}
        </p>
        {window.innerWidth > 640 && (
          <div
            className={`text-[#f3c621] flex ${
              list ? "" : "sm:justify-center"
            } `}
          >
            {/* <StarRating rating={product.average_rating} size={20}></StarRating> */}
          </div>
        )}
        <p>
          {list ? (
            <div
              className="limit-lines"
              dangerouslySetInnerHTML={{ __html: product?.short_description }}
            />
          ) : (
            ""
          )}
        </p>
        {/* <div className={`flex gap-2 ${list ? "" : "sm:justify-center"} `}>
          <p className="text-accent font-bold sm:text-lg mb-2">
            {product?.regular_price} BDT.
          </p>
        </div> */}

        <button
          onClick={() => navigate(`/product/${product.slug}`)}
          className={`add-btn bg-accent text-white px-6 py-2`}
        >
          Read More
        </button>
      </div>
    </div>
  );
};

export default Product;
