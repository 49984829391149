import React from "react";
import "./MainHomePage.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import clarissPromo from '../../Images/Clariss Catalog Promo (1080p, h264, youtube) (online-video-cutter.com) (1).mp4'

const MainHomePage = () => {
  return (
    <div className="MainHome">
      <Helmet>
        <title>Clariss</title>
        <meta
          name="description"
          content="Welcome to Clariss – where nature meets beauty. Discover our curated collection of organic and natural products designed for your well-being. From skincare to home essentials, shop consciously and live beautifully. Embrace the beauty of nature with Clariss."
        />
        <link rel="canonical" href="https://clarissinc.com" />
        <meta property="og:title" content="Clariss" />
        <meta
          property="og:description"
          content="Welcome to Clariss – where nature meets beauty. Discover our curated collection of organic and natural products designed for your well-being. From skincare to home essentials, shop consciously and live beautifully. Embrace the beauty of nature with Clariss."
        />
        <meta property="og:url" content="https://clarissinc.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="video-container">
        <video className="background-video" autoPlay loop muted>
          <source src={clarissPromo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="home-overlay"></div> {/* Overlay for opacity effect */}
      </div>
      <div className="content">
        <Link to="/personal-care-home" className="btn">
          Personal Care
        </Link>
        <Link to="/product-category/food care" className="btn">
          Food Care
        </Link>
      </div>
    </div>
  );
};

export default MainHomePage;
