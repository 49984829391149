import React from "react";
import { useEffect } from "react";
import insta1 from "../../src/Images/insta1.webp";
import insta2 from "../../src/Images/insta2.jpg";
import insta3 from "../../src/Images/insta3.jpg";
import insta4 from "../../src/Images/insta4.jpg";
import insta5 from "../../src/Images/insta5.jpg";

const InstaShop = () => {

  return (
    <div className="overflow-hidden">
        <div className="mt-10 flex flex-col items-center w-[100vw]">
          <p className="text-2xl text-center font-bold">
            Clariss Bangldesh Instashop
          </p>
          <p className="text-xl text-center mt-6 text-accent font-bold">
            Follow Us On Instagram
          </p>
          <div className="grid grid-cols-2 sm:flex sm:flex-row justify-center items-center mt-10 sm:mx-0">
            <div className="overflow-hidden">
              <a
                href="https://www.instagram.com/clarissbangladesh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-[400px] ease-in-out duration-200 hover:scale-125 "
                  src={insta1}
                  alt=""
                />
              </a>
            </div>
            <div className="overflow-hidden">
              <a
                href="https://www.instagram.com/clarissbangladesh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-[400px] ease-in-out duration-200 hover:scale-125 "
                  src={insta2}
                  alt=""
                />
              </a>
            </div>

            <div className="overflow-hidden">
              <a
                href="https://www.instagram.com/clarissbangladesh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-[400px] ease-in-out duration-200 hover:scale-125 "
                  src={insta3}
                  alt=""
                />
              </a>
            </div>
            <div className="overflow-hidden">
              <a
                href="https://www.instagram.com/clarissbangladesh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-[400px] ease-in-out duration-200 hover:scale-125"
                  src={insta4}
                  alt=""
                />
              </a>
            </div>

            <div className="overflow-hidden">
              <a
                href="https://www.instagram.com/clarissbangladesh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="w-[400px] ease-in-out duration-200 hover:scale-125 hidden sm:block  "
                  src={insta5}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
    </div>
  );
};

export default InstaShop;
